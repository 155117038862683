function searchEngineUi($elements, source, param = undefined, fromHeader = false) {
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const Log = logFactory('searchEngineHandler.js');
  const [$searchInput, $submitInput, $deleteButton, $autocomplete] = $elements;
  const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');
  const redirection = GlobalSite.checkDependency('GlobalSite.getFaqRedirection');
  const debounce = GlobalSite.checkDependency('GlobalSite.debounce');
  const answerBlockUi = GlobalSite.checkDependency('GlobalSite.answerBlockUi');
  const categoriesBlockUi = GlobalSite.checkDependency('GlobalSite.categoriesBlockUi');
  const federatedSearchBlocUi = GlobalSite.checkDependency('GlobalSite.federatedSearchBlocUi');
  const getClient = GlobalSite.checkDependency('GlobalSite.getClient');
  const KEYS = {
    enter: 'Enter',
    tab: 'Tab',
    escape: 'Escape',
  };

  let searchInputVal = param || $searchInput.val() || '';
  let $autocompleteItems;

  function sendSearchRequest() {
    if (searchInputVal === '') return;
    const urlToRedirect = {
      cetelem: {
        params: `?q=${searchInputVal}&ac=&uq=&lc=`,
      },
      cofinoga: {
        params: `?q=${searchInputVal}&ac=&uq=&lc=`,
      },
      undefined: {
        params: '',
      },
    };
    const client = urlToRedirect[getClient()];
    window.location.href = `${redirection?.search}${client?.params}`;
  }

  async function searchSuggestionsTemplate(query) {
    const getSearchSuggestions = await inbentaApi.search(
      {
        query,
        splitQuery: true,
        type: 'instant',
      },
      source,
    );
    return getSearchSuggestions?.results
      .map((suggestion) => {
        return `<li class="autocomplete-item${fromHeader ? '-header' : ''}">
          <a data-source="${source}" data-tracking-type="instant_click" data-tracking-value="${
          suggestion?.tracking?.clickCode || ''
        }" href="${redirection?.answer}${suggestion.slug}">${suggestion.titleHighlight}</a>
          </li>`;
      })
      .join('');
  }

  async function getSearchAnswers(query) {
    const searchAnswers = await inbentaApi.search(
      {
        query,
        length: 20,
      },
      source,
    );
    return { results: searchAnswers?.results, tracking: searchAnswers?.tracking };
  }

  async function getFederatedSearchAnswers(query) {
    const federatedSearchAnswers = await inbentaApi.federatedSearch(
      { requests: [{ query, length: 10 }] },
      source,
    );
    return { results: federatedSearchAnswers?.results, tracking: federatedSearchAnswers?.tracking };
  }

  function searchSuggestionsClickHandler(e) {
    if (!e.originalEvent) {
      return Log.log('unecessary');
    }
    if (inbentaApi.isTracking()) {
      e.preventDefault();
      const $this = $(e.target);
      const source = $this.data('source');
      const trackingType = $this.data('trackingType');
      const trackingValue = $this.data('trackingValue');
      inbentaApi.sendTrackingEvents({ type: trackingType, data: { code: trackingValue } }, source);
      window.location.replace($this.attr('href'));
      Log.log({ trackingType, trackingValue, source });
    }
  }

  async function autocomplete() {
    if (searchInputVal.length >= 1) {
      $autocomplete
        .find('.autocomplete-list')
        .html(await searchSuggestionsTemplate(searchInputVal));
      $autocompleteItems = $(`.autocomplete-item${fromHeader ? '-header' : ''}`).find('a');
      $autocompleteItems.on('click', searchSuggestionsClickHandler);
      $autocomplete.show();
    } else {
      $autocomplete.hide();
    }
  }
  $searchInput
    .on('input focus', async () => {
      searchInputVal = $searchInput.val();
      if (searchInputVal.length < 1) {
        $submitInput.prop('disabled', true);
        $deleteButton.hide();
        $autocomplete.hide();
      } else {
        $submitInput.prop('disabled', false);
        $deleteButton.show();
      }
    })
    .on(
      'keyup',
      debounce(async (e) => {
        if (e.key === KEYS.escape) {
          return $autocomplete.hide();
        } else if (e.key === KEYS.enter) {
          $submitInput.trigger('click');
          return;
        } else if (e.key === KEYS.tab) {
          return Log.log(e.key);
        } else {
          autocomplete();
        }
      }, 500),
    );

  $deleteButton.on('click keyup', (e) => {
    if (e.key !== KEYS.enter && e.key !== undefined) {
      return Log.log(e.key);
    }
    $searchInput.val('');
    $submitInput.prop('disabled', true);
    $deleteButton.hide();
    $autocomplete.hide();
  });

  $submitInput.on('click', async (e) => {
    if (searchInputVal === '') return;
    $autocomplete.hide();
    $submitInput.focus();
    if (!fromHeader) {
      const searchAnswers = await getSearchAnswers(searchInputVal);
      answerBlockUi('search', searchAnswers?.results, source);
      const federatedSearchAnswers = await getFederatedSearchAnswers(searchInputVal);
      federatedSearchBlocUi(federatedSearchAnswers?.results, searchAnswers?.tracking, source);
      source === 'faq' && categoriesBlockUi($('#inbenta-category-level1'), 0, true);
    } else {
      sendSearchRequest();
    }
  });

  $('body')
    .on('click', () => {
      $autocomplete.hide();
    })
    .on('keyup', (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        $autocomplete.hide();
      }
    });

  if (param) {
    $submitInput.trigger('click');
  }
}

(function searchEngineHandler() {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');

  exportToGlobalSite({
    searchEngineUi,
  });
})();
